var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import find from "lodash/find";
import FaqItem from "./FaqItem.vue";
export default Vue.extend({
    components: {
        FaqItem: FaqItem,
    },
    apollo: {
        faqCategories: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        {\n          faqCategories {\n            name\n            slug\n            faqs {\n              slug\n              ...FaqItem_faq\n            }\n          }\n        }\n        ", "\n      "], ["\n        {\n          faqCategories {\n            name\n            slug\n            faqs {\n              slug\n              ...FaqItem_faq\n            }\n          }\n        }\n        ", "\n      "])), FaqItem.options.fragments.faq),
            result: function (result) {
                if (!result.data) {
                    return;
                }
                this.tryAndActivateFirstCategory();
                if (this.$route.hash) {
                    this.scrollToFaq(this.$route.hash.replace("#", ""));
                }
            },
        },
    },
    data: function () {
        return {};
    },
    computed: {
        activeCategorySlug: function () {
            return this.$route.params.category;
        },
        activeCategory: function () {
            var _this = this;
            return find(this.faqCategories, function (_a) {
                var slug = _a.slug;
                return slug === _this.activeCategorySlug;
            });
        },
        activeFaqs: function () {
            if (!this.activeCategory) {
                return undefined;
            }
            return this.activeCategory.faqs;
        },
    },
    watch: {
        $route: function (newRoute) {
            if (Object.keys(newRoute.params).length === 0) {
                this.tryAndActivateFirstCategory();
            }
        },
    },
    methods: {
        tryAndActivateFirstCategory: function () {
            if (!this.activeCategorySlug &&
                this.faqCategories &&
                this.faqCategories.length > 0) {
                this.$router.push("/faqs/".concat(this.faqCategories[0].slug));
            }
        },
        scrollToFaq: function (slug) {
            if (!this.activeFaqs) {
                return;
            }
            var targetFaq = this.activeFaqs.find(function (faq) { return slug === faq.slug; });
            if (!targetFaq) {
                return;
            }
            var faqElement = document.getElementById(targetFaq.slug);
            if (!faqElement) {
                return;
            }
            var bodyRect = document.body.getBoundingClientRect();
            var faqRect = faqElement.getBoundingClientRect();
            window.scrollTo(0, faqRect.top - bodyRect.top);
        },
    },
});
var templateObject_1;
