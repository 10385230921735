var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
export default Vue.extend({
    props: {
        faq: {
            type: Object,
            required: true,
        },
    },
    fragments: {
        faq: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment FaqItem_faq on Faq {\n        slug\n        answer\n        question\n      }\n    "], ["\n      fragment FaqItem_faq on Faq {\n        slug\n        answer\n        question\n      }\n    "]))),
    },
    data: function () {
        return {
            isOpen: false,
        };
    },
    watch: {
        $route: function (to, from) {
            if (to.hash !== from.hash) {
                this.openIfHashMatches();
            }
        },
    },
    mounted: function () {
        this.openIfHashMatches();
    },
    methods: {
        onLinkClick: function (e) {
            var _this = this;
            e.stopImmediatePropagation();
            this.isOpen = true;
            this.$nextTick(function () {
                if (_this.$route.hash !== "#".concat(_this.faq.slug)) {
                    _this.$router.push({
                        path: _this.$route.path,
                        hash: _this.faq.slug,
                    });
                }
                _this.$emit("link");
            });
        },
        onToggleOpen: function () {
            this.isOpen = !this.isOpen;
        },
        openIfHashMatches: function () {
            if (this.$route.hash === "#".concat(this.faq.slug)) {
                this.isOpen = true;
            }
        },
    },
});
var templateObject_1;
